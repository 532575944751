.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.background {
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-image: url("./assets/background.jpg");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.backgroundAbout {
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  /* Full height */
  height: 100%;
}

a {
  text-decoration: none;
}

.buttonSign {
  padding: 10px 20px 10px 20px;
  background-color: blue;
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  cursor: pointer;
}

.txtBlue {
  color: #36c2e8;
}

.txtBold {
  font-weight: 700;
}

.txtBoldLite {
  font-weight: 400;
}

.nav-link {
  color: #36c2e8;
  padding-left: 10px;
}

.unselected {
  font-weight: bold;
}

/**
 * Fonts
 */

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");

/**
  * Reset
  */
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

a,
a:visited {
  background-color: transparent;
  color: #0067b8;
  text-decoration: none;
  /* border-bottom: 1px dotted; */
}

a:hover,
a:active {
  border: none;
  outline: 0;
}

a:focus {
  border: none;
  outline: none;
}

a img {
  border: 0;
}

.entry-content>*:not(:last-child) {
  margin-bottom: 1rem;
}

/**
  * Article Container
  */
.article-container {
  padding: 2rem;
}

/**
  * Article Block
  */
.article-block {
  text-align: center;
}

.article-block .entry-content>p {
  color: #666;
}

/**
  * Tabs Container
  */
.tabs-container {
  padding: 2rem;
}

/**
  * Tabs Block
  */
.tabs-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
  * Tabs
  */
.tabs {
  display: flex;
}

.tabs>ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 15%;
}

.tabs>ul li {
  display: block;
}

.tabs>ul li:not(:last-child) {
  border-bottom: 1px solid #262626;
}

.tabs>ul li a,
.tabs>ul li a:visited {
  display: flex;
  border-bottom: none;
  text-decoration: none;
  background-color: #fff;
  color: #000;
  padding: 1rem 1.5rem;
  transition: all 0.2s ease-in-out;
  word-wrap: break-word;
}

.tabs>ul li a:hover,
.tabs>ul li a:focus,
.tabs>ul li a:active {
  border-bottom: none;
  outline: 0;
}

.tabs>ul li a.active {
  background-color: #f2f2f2;
  color: #0067b8;
}

.tabs>ul li a:hover:not(.active) {
  color: #0067b8;
}

.tabs>ul li a>span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tabs>ul li a>span.tab-label {
  display: none;
}

.tabs section {
  width: 85%;
  background-color: #0000;
  padding: 1rem;
  display: none;
  word-wrap: break-word;
  border-bottom: 6px solid #0067b8;
}

.tab-body {
  margin-top: -50px;
}

.tabs section>* {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s, transform 0.2s;
}

.tabs section>*:nth-child(1) {
  transition-delay: 0.2s;
}

.tabs section>*:nth-child(2) {
  transition-delay: 0.3s;
}

.tabs section>*:nth-child(3) {
  transition-delay: 0.4s;
}

.tabs section>*:nth-child(4) {
  transition-delay: 0.5s;
}

.tabs section>*:nth-child(5) {
  transition-delay: 0.6s;
}

.tabs section>*:nth-child(6) {
  transition-delay: 0.7s;
}

.tabs section>*:nth-child(7) {
  transition-delay: 0.8s;
}

.tabs section>*:nth-child(8) {
  transition-delay: 0.9s;
}

.tabs section>*:nth-child(9) {
  transition-delay: 1s;
}

.tabs section>*:nth-child(10) {
  transition-delay: 1.1s;
}

.tabs section>*:nth-child(11) {
  transition-delay: 1.2s;
}

.tabs section>*:nth-child(12) {
  transition-delay: 1.3s;
}

.tabs section>*:nth-child(13) {
  transition-delay: 1.4s;
}

.tabs section>*:nth-child(14) {
  transition-delay: 1.5s;
}

.tabs section>*:nth-child(15) {
  transition-delay: 1.6s;
}

.tabs section>*:nth-child(16) {
  transition-delay: 1.7s;
}

.tabs section>*:nth-child(17) {
  transition-delay: 1.8s;
}

.tabs section>*:nth-child(18) {
  transition-delay: 1.9s;
}

.tabs section>*:nth-child(19) {
  transition-delay: 2s;
}

.tabs section>*:nth-child(20) {
  transition-delay: 2.1s;
}

.tabs section.active {
  display: block;
}

.tabs section.active-content>* {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 576px) {
  .tabs>ul {
    width: 150px;
  }

  .tabs>ul li a {
    flex-wrap: wrap;
  }

  .tabs>ul li a>span {
    width: 100%;
  }

  .tabs>ul li a>span.tab-label {
    width: 100%;
    display: block;
    margin-top: 0.2rem;
  }

  .tabs section {
    width: calc(100% - 150px);
    padding: 2rem 2rem;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 1.125rem;
  }

  .tabs-container {
    padding: 4rem 4rem;
  }
}

@media (min-width: 992px) {
  .tabs {
    width: 800px;
  }
}
